<template>
    <v-row justify="center">
        <v-col cols="12">
            <v-dialog v-model="dialog.status" max-width="575" persistent>
                <v-card class="pa-5">
                    <v-card-title class="text-h4">
                        <span class="mx-auto">{{dialog.title}}</span>
                    </v-card-title>
                    <div class="text-center py-3">
                        <v-icon size="100" :color="dialog.iconColor">{{dialog.icon}}</v-icon>
                    </div>
                    <v-card-text class="text-h6 text-center">{{dialog.msg}}</v-card-text>
                    <v-card-actions>
                        <v-spacer />
                        <v-btn color="primary" elevation="0" class="px-10" @click="dialog.status = false, dialogTela=false">OK</v-btn>
                        <v-spacer />
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-tooltip  color="primary" bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn :disabled="!dados.nome" class="float-end primary" @click="dialogTela = true" icon v-bind="attrs" v-on="on"> <!-- :to="`/cliente/alterar/${dados.idcliente}`" -->
                        <v-icon small color="white">mdi-pencil</v-icon>
                    </v-btn>
                </template>
                <span> Alterar cadastro</span>
            </v-tooltip>
            <v-dialog v-model="dialogTela" persistent scrollable>
                <v-card ref="form" class="pb-2" max-width="1300">
                    <v-card-title>Alteração de Cadastro</v-card-title>
                    <v-divider />
                    <v-card-subtitle class="pb-0 px-0">
                        <v-tabs v-model="tab" grow>
                            <v-tab href="#tab-1" class="px-3 px-sm-3 px-md-0" active-class="indigo lighten-5">
                                <v-icon>mdi-card-account-details</v-icon>
                                <span class="ml-2 d-none d-sm-block">Dados Principais</span>
                            </v-tab>
                            <v-tab href="#tab-2" active-class="indigo lighten-5">
                                <v-icon>mdi-contacts</v-icon>
                                <span class="ml-2 d-none d-sm-block" >Contatos</span>
                            </v-tab>
                            <v-tab href="#tab-3" class="px-3 px-sm-3 px-md-0" active-class="indigo lighten-5">
                                <v-icon>mdi-map-marker</v-icon>
                                <span class="ml-1 d-none d-sm-block">Localização</span>
                            </v-tab>
                        </v-tabs>
                    </v-card-subtitle>
                    <v-card-text v-if="dados.contato" class="py-0 pt-1 px-6">
                        <v-tabs-items v-model="tab">
                            <v-tab-item value="tab-1">
                                <v-row class="py-5 pt-0 pt-sm-6">
                                    <v-col cols="12" lg="4" md="4" sm="8" class="py-0">
                                        <v-radio-group hide-details v-model="dados.tipoPessoa" row>
                                            <v-col class="px-0 mt-n1" cols="6">
                                                <v-radio label="Pessoa Fisíca" value="F"></v-radio>
                                            </v-col>
                                            <v-col class="px-0 mt-n1" cols="6">
                                                <v-radio label="Pessoa Jurídica" value="J"></v-radio>
                                            </v-col>
                                        </v-radio-group>
                                    </v-col>
                                    <v-col cols="12" lg="2" md="2" sm="4" class="py-0">
                                        <div class="text-body-1 font-weight-bold">Status</div>
                                        <v-select
                                            class="mb-n3"
                                            :items="['Ativo','Inativo']"
                                            v-model="dados.flaginativo"
                                            dense
                                            outlined
                                            :disabled="!permissao('alterar_status_cliente')"
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" xl="6" lg="6" md="6" class="py-0"> <!--apelidoFantasia-->
                                        <div v-if="dados.tipoPessoa == 'F'" class="text-body-1 font-weight-bold">Apelido</div>
                                        <div v-if="dados.tipoPessoa == 'J'" class="text-body-1 font-weight-bold">Nome Fantasia</div>
                                        <v-text-field
                                            class="mb-n3"
                                            v-model="dados.apelidoFantasia"
                                            ref="apelido"
                                            :loading="carregando"
                                            outlined
                                            dense
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" xl="6" lg="6" md="6" class="py-0"> <!--nomeRazao-->
                                        <div v-if="dados.tipoPessoa == 'F'" class="text-body-1 font-weight-bold">*Nome</div>
                                        <div v-if="dados.tipoPessoa == 'J'" class="text-body-1 font-weight-bold">*Razão Social</div>
                                        <v-text-field
                                            class="mb-n3"
                                            ref="nome"
                                            v-model="dados.nome"
                                            outlined
                                            :loading="carregando"
                                            dense
                                            required
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" xl="6" lg="6" md="6" class="py-0" v-if="dados.tipoPessoa == 'F'"> <!--cpf-->
                                        <div class="text-body-1 font-weight-bold">*CPF</div>
                                        <v-text-field
                                            class="mb-n3"
                                            ref="CPF"
                                            v-model="dados.cpfcnpj"
                                            v-mask="'###.###.###-##'"
                                            outlined
                                            dense
                                            required
                                            :loading="carregando"
                                            :disabled = "!permissao('alterar_cnpj_cpf')"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" xl="6" lg="6" md="6" class="py-0" v-if="dados.tipoPessoa == 'J'"> <!--cnpj-->
                                        <div class="text-body-1 font-weight-bold">*CNPJ</div>
                                        <v-text-field
                                            class="mb-n3"
                                            ref="CPF"
                                            v-model="dados.cpfcnpj"
                                            v-mask="'##.###.###/####-##'"
                                            outlined
                                            dense
                                            required
                                            :loading="carregando"
                                            :disabled = "!permissao('alterar_cnpj_cpf')"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" xl="6" lg="6" md="6" class="py-0"> <!--atividade-->
                                        <div class="text-body-1 font-weight-bold">*Atividade</div>
                                        <v-autocomplete
                                            class="mb-n3"
                                            ref="cidade"
                                            v-model="dados.idatividade"
                                            :items="atividades"
                                            outlined
                                            dense
                                            placeholder="Selecione uma atividade"
                                            required
                                            :loading="carregando"
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" xl="6" lg="6" md="6" class="py-0"> <!--inscricaoEstadual-->
                                        <div class="text-body-1 font-weight-bold">
                                            *Inscrição estadual
                                            <v-tooltip right>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        small
                                                        color="light-blue"
                                                        class="mt-n1">mdi-information-outline</v-icon>
                                                </template>
                                                <span>Caso cliente não possua, informar isento</span>
                                            </v-tooltip>
                                        </div>
                                        <v-text-field
                                            class="mb-n8"
                                            v-model="dados.ie"
                                            outlined
                                            dense
                                            required
                                            :loading="carregando"
                                        ></v-text-field>
                                        <v-row>
                                            <v-col cols="6" class="mb-3">
                                                <v-checkbox
                                                    v-model="dados.flagiebaixada"
                                                    label="IE Baixada"
                                                    hide-details
                                                    class="ma-0"
                                                    :disabled="this.dados.tipoPessoa == 'F' ? true : false"
                                                ></v-checkbox>
                                            </v-col>
                                            <v-col cols="6">
                                                <v-checkbox
                                                    v-model="dados.flagmei"
                                                    label="MEI"
                                                    hide-details
                                                    class="ma-0"
                                                    :disabled="this.dados.tipoPessoa == 'F' ? true : false"
                                                ></v-checkbox>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-tab-item>

                            <v-tab-item value="tab-2">
                                <v-row class="py-5 pt-7">
                                    <v-col cols="12" xl="6" lg="6" md="6" sm="6" class="py-0">
                                        <div class="text-body-1 font-weight-bold">Contato Principal</div>
                                        <v-text-field
                                            class="mb-n3"
                                            ref="CPF"
                                            v-model="dados.contato.contato"
                                            outlined
                                            dense
                                            required
                                            :loading="carregando"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" xl="6" lg="6" md="6" sm="6" class="py-0">
                                        <div class="text-body-1 font-weight-bold">Contato Secundário</div>
                                        <v-text-field
                                            class="mb-n3"
                                            ref="CPF"
                                            v-model="dados.contato.contato2"
                                            outlined
                                            dense
                                            required
                                            :loading="carregando"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" xl="6" lg="6" md="6" sm="6" class="py-0">
                                        <div class="text-body-1 font-weight-bold">Celular</div>
                                        <v-text-field
                                            v-mask="'(##)#####-####'"
                                            class="mb-n3"
                                            ref="celular"
                                            v-model="dados.contato.celular"
                                            outlined
                                            dense
                                            required
                                            :loading="carregando"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" xl="6" lg="6" md="6" sm="6" class="py-0">
                                        <div class="text-body-1 font-weight-bold">Telefone Comercial</div>
                                        <v-text-field
                                            v-mask="'(##)#####-####'"
                                            class="mb-n3"
                                            ref="CPF"
                                            v-model="dados.contato.telefoneComercial"
                                            outlined
                                            dense
                                            required
                                            :loading="carregando"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" xl="6" lg="6" md="6" sm="6" class="py-0">
                                        <div class="text-body-1 font-weight-bold">E-mail</div>
                                        <v-text-field
                                            class="mb-n3"
                                            ref="CPF"
                                            v-model="dados.contato.email"
                                            outlined
                                            dense
                                            required
                                            :loading="carregando"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" xl="6" lg="6" md="6" sm="6" class="py-0">
                                        <div class="text-body-1 font-weight-bold">E-mail Financeiro</div>
                                        <v-text-field
                                            class="mb-n3"
                                            ref="CPF"
                                            v-model="dados.contato.emailfinanceiro"
                                            outlined
                                            dense
                                            required
                                            :loading="carregando"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-tab-item>

                            <v-tab-item value="tab-3">
                                <v-row class="py-5 pt-7">
                                    <v-col cols="12" xl="2" lg="4" md="4" sm="4" class="py-0">
                                        <div class="text-body-1 font-weight-bold">*CEP</div>
                                        <v-text-field
                                            class="mb-n3"
                                            ref="CEP"
                                            v-model="dados.endereco.cep"
                                            outlined
                                            required
                                            dense
                                            v-mask="'#####-###'"
                                            :loading="carregando"
                                            @change="listarCeps()"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="9" xl="3" lg="6" md="6" sm="6" class="py-0">
                                        <div class="text-body-1 font-weight-bold">*Cidade</div>
                                        <v-text-field
                                            class="mb-n3"
                                            ref="Cidade"
                                            v-model="dados.endereco.cidade"
                                            :loading="carregando"
                                            outlined
                                            dense
                                            required
                                            disabled
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="3" xl="1" lg="2" md="2" sm="2" class="py-0">
                                        <div class="text-body-1 font-weight-bold">*UF</div>
                                        <v-text-field
                                            class="mb-n3"
                                            ref="Estado"
                                            v-model="dados.endereco.uf"
                                            :loading="carregando"
                                            outlined
                                            dense
                                            required
                                            disabled
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" xl="4" lg="6" md="6" sm="9" class="py-0">
                                        <div class="text-body-1 font-weight-bold">*Endereço</div>
                                        <v-text-field
                                            class="mb-n3"
                                            ref="Endereco"
                                            v-model="dados.endereco.endereco"
                                            :loading="carregando"
                                            outlined
                                            dense
                                            required
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="4" xl="2" lg="2" md="2" sm="3" class="py-0">
                                        <div class="text-body-1 font-weight-bold">*Número</div>
                                        <v-text-field
                                            class="mb-n3"
                                            ref="Numero"
                                            v-model="dados.endereco.numero"
                                            :loading="carregando"
                                            outlined
                                            dense
                                            required
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="8" xl="4" lg="4" md="4" sm="6" class="py-0">
                                        <div class="text-body-1 font-weight-bold">*Bairro</div>
                                        <v-text-field
                                            class="mb-n3"
                                            ref="Bairro"
                                            v-model="dados.endereco.bairro"
                                            :loading="carregando"
                                            outlined
                                            dense
                                            required
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" xl="3" lg="5" md="5" sm="6" class="py-0">
                                        <div class="text-body-1 font-weight-bold">Região</div>
                                        <v-autocomplete
                                            class="mb-n3"
                                            v-model="dados.endereco.idregiao"
                                            :items="regioes"
                                            :loading="carregando"
                                            item-text="regiao"
                                            item-value="idregiao"
                                            outlined
                                            dense
                                            required
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" xl="5" lg="7" md="7" sm="12" class="py-0">
                                        <div class="text-body-1 font-weight-bold">Complemento</div>
                                        <v-text-field
                                            class="mb-n3"
                                            ref="Complemento"
                                            v-model="dados.endereco.complemento"
                                            :loading="carregando"
                                            outlined
                                            dense
                                            required
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" class="py-0">
                                        <div class="text-body-1 font-weight-bold">Obeservação</div>
                                        <v-textarea
                                            class="mb-n3"
                                            v-model="dados.endereco.observacao"
                                            outlined
                                            :loading="carregando"
                                            dense
                                        ></v-textarea>
                                    </v-col>
                                </v-row>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-card-text>

                    <v-alert class="mx-6 mt-0 mb-2" type="error" v-if="erro" v-html="erro"/>
                    <v-card-actions class="pt-0">
                        <v-spacer></v-spacer>
                        <v-btn color="primary" text @click="dialogTela = false, listar()" class="mr-1">Cancelar</v-btn>
                        <v-btn color="primary" @click="atualizarCliente()" class="mr-2">Salvar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-col>
    </v-row>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";

export default {
    name: "ClienteNovo",
    props: ["idcliente"],
    data: () => ({
        tab: null,
        dialogTela : false,
        dados : {
            flagiebaixada: false,
            flagmei: false,
            tipoPessoa: "F",
            cidade : '',
            estado : ''
        },
        ceps: { lista: [] },
        atividades: [],
        regioes: [],

        dialog: { status: false },
        carregando: false,
        erro : null
    }),
    methods: {
        listar() {
            this.carregando = true;
            return axios
                .post(`${this.backendUrl}cliente/get`, {
                    idcliente : parseInt(this.idcliente)
                })
                .then((res) => {
                    this.dados = res.data
                    this.dados.flagiebaixada = this.dados.flagiebaixada == 'F' ? false : true
                    this.dados.flagmei = this.dados.flagmei == 'F' ? false : true
                    this.dados.flaginativo = this.dados.flaginativo == 'F' ? 'Ativo' : 'Inativo'
                    this.carregando = false;
                    this.erro = null
                })
                .catch(() => {
                    this.carregando = false;
                });
        },

        atualizarCliente() {
            this.carregando = true;
            return axios
                .post(`${this.backendUrl}cliente/atualizar`, {
                    idcliente: parseInt(this.idcliente),
                    tipoPessoa: this.dados.tipoPessoa,
                    nome: this.dados.nome ? this.dados.nome.toUpperCase() : '',
                    apelidoFantasia: this.dados.apelidoFantasia ? this.dados.apelidoFantasia.toUpperCase(): '',
                    cpfcnpj: this.dados.cpfcnpj ? this.dados.cpfcnpj.replace(/[^0-9]/g, '') : '',
                    idatividade: this.dados.idatividade,
                    ie: this.dados.ie,
                    flagiebaixada: this.dados.flagiebaixada ? 'T' : 'F',
                    flagmei: this.dados.flagmei ? 'T' : 'F',
                    flaginativo: this.dados.flaginativo == 'Ativo' ? 'F' : 'T',
                    contato:{
                        contato: this.dados.contato.contato,
                        contato2: this.dados.contato.contato2,
                        celular: this.dados.contato.celular ? this.dados.contato.celular.replace(/[^0-9]/g, '') : '',
                        telefoneComercial: this.dados.contato.telefoneComercial ? this.dados.contato.telefoneComercial.replace(/[^0-9]/g, '') : '',
                        email: this.dados.contato.email,
                        emailfinanceiro: this.dados.contato.emailfinanceiro,
                    },
                    endereco: {
                        cep: this.dados.endereco.cep ? this.dados.endereco.cep.replace(/[^0-9]/g, '') : '',
                        idcidade: this.dados.endereco.idcidade,
                        cidade: this.dados.endereco.cidade,
                        uf: this.dados.endereco.uf,
                        endereco: this.dados.endereco.endereco ? this.dados.endereco.endereco.toUpperCase() : '',
                        numero: this.dados.endereco.numero,
                        bairro: this.dados.endereco.bairro,
                        idregiao: this.dados.endereco.idregiao,
                        complemento: this.dados.endereco.complemento,
                        observacao: this.dados.endereco.observacao,
                    }
                    //...this.dados,
                })
                .then(() => {
                    this.carregando = false;
                    this.dialog.status = true
                    this.dialog.title = 'Alterado'
                    this.dialog.msg = 'Cadastro atualizado com sucesso!'
                    this.dialog.icon = 'mdi-check-circle-outline'
                    this.dialog.iconColor = 'success'
                    this.listar()
                    // if (typeof res.data === "number") {
                    //     this.dialog.res = `/cliente/get/${res.data}`
                    //     //return this.$router.push(`/cliente/get/${res.data}`);
                    // }
                })
                .catch((r) => {
                    this.erro = r.response.data.erro.replaceAll(';', '<br/>')
                    this.carregando = false;
                });
        },

        listarAtividades() {
            return axios
                .post(`${this.backendUrl}cliente/atividade/listar`)
                .then((res) => {
                    this.atividades = res.data.map((v) => {
                        return {
                            value : v.idatividade,
                            text : `${v.idatividade} - ${v.atividade}`
                        }
                    });
                });
        },

        listarRegioes() {
            return axios
                .post(`${this.backendUrl}regioes/listar`)
                .then((res) => {
                    this.regioes = res.data
                });
        },

        listarCeps() {
            this.ceps.lista = [];
            return axios
                .post(`${this.backendUrl}endereco/cep/get`, {
                    cep: this.dados.endereco.cep.replace('-',''),
                    limit: 99999,
                    offset: 0,
                })
                .then((res) => {
                    this.dados.endereco.cidade = res.data.cidade.trim()
                    this.dados.endereco.bairro = res.data.bairro.trim()
                    this.dados.endereco.uf = res.data.uf.trim()
                    this.dados.endereco.endereco = res.data.endereco.trim()
                    this.dados.endereco.idcidade = res.data.idcidade
                    if (res.data.lista != null) {
                        this.ceps = res.data;
                    }
                })
                .catch(() => {
                    this.dados.endereco.cidade = ""
                    this.dados.endereco.bairro = ""
                    this.dados.endereco.uf = ""
                    this.dados.endereco.endereco = ""
                });
        },

        async init() {
            await this.listarAtividades();
            await this.listarRegioes();
            await this.listar();
        },
    },
    watch:{
        "dados.tipoPessoa"(){
            if (this.dados.tipoPessoa=='F') {
                this.dados.flagmei = false
            }
        }
    },

    computed: {
        ...mapState(["pgLimit","backendUrl"]),
    },

    created() {
        this.init();
    },
};
</script>

<style scoped>
fieldset {
    padding: 10px;
    /*border: solid rgba(37, 37, 37, 0.429) 1px;*/
    border: solid rgba(2, 52, 73, 0.35) 1px;
    border-radius: 4px;
}
fieldset legend {
    padding: 0px 5px;
}
</style>